// import { functions } from "lodash";
import api from "../api/api";
const base64Prefix = "data:image/jpg;base64,";

const sessionKey = "myStorage";
const tool = {
  showMask() {
    try {
      if (top.showMask) {
        top.showMask();
      }
    } catch (err) {
      console.log(err);
    }
  },
  hideMask() {
    try {
      if (top.hideMask) {
        top.hideMask();
      }
    } catch (err) {
      console.log(err);
    }
  },
  postMessage(win, type, data, methods) {
    let options = { type: type, data: data, methods: methods };
    win.postMessage(options, "*");
  },
  fullScreen(element) {
    // let element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  },
  exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  },
  // 检查是否全屏
  checkFull() {
    var isFull =
      document.fullscreenEnabled ||
      window.fullScreen ||
      document.webkitIsFullScreen ||
      document.msFullscreenEnabled;
    //to fix : false || undefined == undefined
    if (isFull === undefined) {
      isFull = false;
    }
    return isFull;
  },
  addLocalStorage(value) {
    let pathArr = location.pathname.split("/");
    let key = pathArr[pathArr.length - 1].replace(".", "_") + "_" + +new Date();
    localStorage.setItem(key, value);
    return key;
  },
  getLocalStorage() {
    let key = this.getQueryString("_StorageId");
    return localStorage.getItem(key);
  },
  combineBase64url(base64) {
    return base64 ? base64Prefix + base64 : base64;
  },
  remove() {
    let key = this.getQueryString("_StorageId");
    localStorage.removeItem(key);
  },
  //临时缓存
  getTempItem: function (key) {
    return tool.getSessionData()[key];
  },
  setTempItem: function (key, value) {
    let data = tool.getSessionData();
    data[key] = value;
    tool.setSessionStorage(data);
  },
  setSessionStorage(data) {
    localStorage.setItem(sessionKey, JSON.stringify(data));
  },
  getSessionData() {
    const data = localStorage.getItem(sessionKey);
    if (!data) {
      return {};
    }
    return JSON.parse(data);
  },
  /**
   * 比较时间间隔
   * @param begintime 开始时间2020-02-01 20：20：20
   * @param endtime 结束时间 2021-02-01 11：20 ： 20
   */
  compareTime(begintime, endtime) {
    if (!endtime) {
      return true;
    }
    if (begintime == "") {
      alert("开始时间不能为空");
      return false;
    }
    if (endtime == "") {
      alert("结束时间不能为空");
      return false;
    }
    let time1 = Date.parse(begintime.replace(/-/g, "/"));
    let time2 = Date.parse(endtime.replace(/-/g, "/"));
    if (time1 > time2) {
      alert("开始时间不能大于结束时间");
      return false;
    }
    if (time2 - time1 > 90 * 24 * 60 * 60 * 1000) {
      alert("时间跨度不得超过90天！");
      return false;
    }
    return true;
  },
  //是否过期
  expire(longtime, expireTime) {
    return new Date().getTime() - longtime > expireTime;
  },
};
tool.mymixin = {
  methods: {
    setIframeScroll: function (num) {
      try {
        this.$nextTick(() => {
          tool.setParentHeight(num);
        });
      } catch (e) {
        console.log(e);
      }
      try {
        this.$nextTick(() => {
          top.reSetIframeHeight && top.reSetIframeHeight();
          top.setIframeHeight && top.setIframeHeight();
        });
      } catch (e) {
        console.log(e);
      }
    },
    handelViewVideoRealTime(item) {
      sessionStorage.setItem("vaucVideoSpfn", "1");
      let videoPlayType = "";
      let systemConfig = localStorage.getItem("systemConfig");
      if (systemConfig) {
        let config = JSON.parse(systemConfig);
        videoPlayType = config.videoPlayer;
      }
      if (videoPlayType && videoPlayType == "webapp") {
        this.viewVideo(item);
      } else if (videoPlayType && videoPlayType == "vauc") {
        this.isPlay = true;
        this.viewVideoVaucRealTIme(item);
      } else {
        // alert("未配置播放控件！");
        tool.showMsg(this, "未配置播放控件！", "warning");
      }
    },
    //根据配置判断视频播放方式
    handelViewVideo(item) {
      let videoPlayType = "";
      let systemConfig = localStorage.getItem("systemConfig");
      if (systemConfig) {
        let config = JSON.parse(systemConfig);
        videoPlayType = config.videoPlayer;
      }
      if (videoPlayType && videoPlayType == "webapp") {
        this.viewVideo(item);
      } else if (videoPlayType && videoPlayType == "vauc") {
        this.isPlay = true;
        this.viewVideoVauc(item);
      } else {
        // alert("未配置播放控件！");
        tool.showMsg(this, "未配置播放控件！", "warning");
      }
    },
    //视频回放
    viewVideo(item) {
      this.handleReplay(item, this.userInfo);
    },
    // 录像回放-webapp
    replayVideo(that, e, userInfo) {
      var fdId = e.FDID || e.fdId || e.fdid || e.FdId;
      var ALARMTIMESTR =
        e.ALARMTIMESTR ||
        e.firsttimestr ||
        (e.alarmtime && tool.formatTime(e.alarmtime)) ||
        e.AlarmTime;
      if (!fdId) {
        // alert("未查到设备！");
        tool.showMsg(this, "未查到设备！", "warning");

        return;
      }
      if (!userInfo.UasIp) {
        // alert("未配置UasIp！");
        tool.showMsg(this, "未配置UasIp！", "warning");
        return;
      }
      let begtime = tool.handelDateChangeStr(ALARMTIMESTR);
      let endtime = tool.handelDateChangeStr(
        tool.addThreeS(ALARMTIMESTR, 20000)
      ); //结束时间加20s;

      let FDID = fdId.slice(0, 6) + "-" + fdId + "-0001-0001";

      let url =
        "webapp://add=" +
        userInfo.UasIp +
        "&port=" +
        userInfo.UasPort +
        "&usr=" +
        userInfo.EmailAddr +
        "&pwd=" +
        userInfo.UserPw +
        "&md5=" +
        userInfo.UserPw +
        "&rec=" +
        FDID +
        "&rst=1&wnum=1&beg=" +
        begtime +
        "&end=" +
        endtime;
      tool.openWebapp(url, that);
    },
    handleReplay(item, userInfo) {
      let _mon = tool.getComplexId(
        item.Id.split("-")[0],
        item.Id.split("-")[1]
      );
      let url =
        "webapp://add=" +
        userInfo.UasIp +
        "&usr=" +
        userInfo.EmailAddr +
        "&port=" +
        userInfo.UasPort +
        "&pwd=" +
        userInfo.UserPw +
        "&md5=" +
        userInfo.UserPw +
        "&rst=1&mon=" +
        _mon;
      let that = this;
      tool.openWebapp(url, that);
    },
    //视频回放--vauc版本
    viewVideoVauc(item) {
      if (item.FdId && item.ChannelId) {
        let playId = item.FdId + "-" + item.ChannelId;
        let name = encodeURIComponent(item.ChannelName);
        let time = encodeURIComponent(item.AlarmTime);
        //    创建iframe
        //// let html = `<iframe id="vaucVideoIframe" src= allowtransparency="true" frameborder="0" class="feature-iframe" style=></iframe>`
        // let iframe = document.createElement('iframe');
        // iframe.id = 'vaucVideoIframe';
        // iframe.src=`/app-map/map/videoPlayback.html?Name=${name}&Id=${playId}&Time=${time}`;
        // iframe.class = "feature-iframe";
        // iframe.style = "width:1000px;height:600px;position: fixed;top:100px;left:50%;margin-left: -500px;z-index: 1000;border:0";
        // iframe.allowtransparency = true;
        // document.body.appendChild(iframe)
        document.getElementById(
          "vaucVideoIframe"
        ).src = `/app-map/map/videoPlayback.html?Name=${name}&Id=${playId}&Time=${time}`;
      } else {
        // alert("未获取到设备信息！");
        tool.showMsg(this, "未获取到设备信息！", "warning");
      }
    },
    viewVideoVaucRealTIme(item) {
      if (item.FdId) {
        let auth = "1";
        if (item.HasDeviceOpt && item.HasDeviceOpt != 1) {
          auth = "0";
        }
        let title = item.DeviceName || "视频播放";
        // sessionStorage.setItem('vaucVideoList',JSON.stringify([{id:'200000000100011869-1',title:title,hasAuth:auth}]));
        sessionStorage.setItem(
          "vaucVideoList",
          JSON.stringify([
            {
              id: item.FdId + "-" + item.ChannelId,
              title: title,
              hasAuth: auth,
            },
          ])
        );
        // let iframe = document.createElement('iframe');
        // iframe.id = 'vaucVideoIframe';
        // iframe.src=`/app-map/map/videoPlay.html`;
        // iframe.class = "feature-iframe drag-header";
        // iframe.style = "width:1000px;height:600px;position: fixed;top:100px;left:50%;margin-left: -500px;z-index: 1000;border:0";
        // iframe.allowtransparency = true;
        // document.body.appendChild(iframe)
        // http://172.16.66.72:3001
        document.getElementById(
          "vaucVideoIframe"
        ).src = `/app-map/map/videoPlay.html`;
        //  this.$refs.videoplayIframe.src = `/app-map/map/videoPlay.html`;
      } else {
        this.isPlay = false;
        // alert("未获取到设备信息！");
        tool.showMsg(this, "未获取到设备信息！", "warning");
      }
    },
    // 监听vaucIframe关闭
    closeVideoVauc() {
      let _this = this;
      window.addEventListener("message", function (e) {
        if (e.data.type == "closeVideoIframe") {
          _this.isPlay = false;
          document.getElementById("vaucVideoIframe").src = "";
        }

        // if(e.data.type=='closeVideoIframe'){
        //   document.body.removeChild(document.getElementById('vaucVideoIframe'))
        // }
      });
    },
  },
};
/**
 * 图片转base64
 * @param {File} file 文件对象
 * @returns {Promise} promise对象
 */
tool.getBase64 = (file) => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    let imgResult = "";
    reader.readAsDataURL(file);
    reader.onload = function () {
      imgResult = reader.result;
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.onloadend = function () {
      resolve(imgResult);
    };
  });
};

tool.getQueryParams = function () {
  if (location.href.indexOf("?") === -1) return {};

  let params = {};
  let paramsStr = location.href.split("html?")[1];
  let arr = paramsStr.indexOf("&") > -1 ? paramsStr.split("&") : [paramsStr];

  for (let i = 0, len = arr.length; i < len; i++) {
    let res = arr[i].split("=");
    params[res[0]] = decodeURIComponent(res[1]);
  }

  return params;
};

//将时间字符串转为特定时间格式 如：20210628000000 -》 2021-06-28 00:00:00
tool.formatTime = function (time) {
  if (time) {
    if (
      /\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}/g.test(time) ||
      /\d{4}-\d{2}-\d{2}/g.test(time)
    ) {
      return time;
    } else if (/\d{14}/g.test(time)) {
      return (
        time.slice(0, 4) +
        "-" +
        time.slice(4, 6) +
        "-" +
        time.slice(6, 8) +
        " " +
        time.slice(8, 10) +
        ":" +
        time.slice(10, 12) +
        ":" +
        time.slice(12, 14)
      );
    } else if (/\d{8}/g.test(time)) {
      let timeStr = time + "";
      return (
        timeStr.slice(0, 4) +
        "-" +
        timeStr.slice(4, 6) +
        "-" +
        timeStr.slice(6, 8)
      );
    }
  } else {
    return "";
  }
};

/**
 * 获取当前时间相对于当天0点的秒数
 * @param {String} dayStr 年月日
 * @param {String} timeStr 时分秒
 * @returns
 */
tool.getDaySeconds = function (dayStr, timeStr) {
  return (
    (new Date(`${dayStr} ${timeStr}`) - new Date(`${dayStr} 00:00:00`)) / 1000
  );
};
/**
 * 获取时间相差天数
 * @param begintime 开始时间2020-02-01 20：20：20
 * @param endtime 结束时间 2021-02-01 11：20 ： 20
 */
tool.chaDays = function (begintime, endtime) {
  let time1 = Date.parse(begintime.replace(/-/g, "/"));
  let time2 = Date.parse(endtime.replace(/-/g, "/"));
  if (time2 - time1 > 0) {
    return Math.floor((time2 - time1) / (24 * 60 * 60 * 1000));
  } else {
    return 0;
  }
};
tool.betterDays = function (begintime, endtime) {
  let time1 = Date.parse(begintime.replace(/-/g, "/"));
  let time2 = Date.parse(endtime.replace(/-/g, "/"));
  if (time2 - time1 > 0) {
    return true;
  } else {
    return false;
  }
};

// 获取指定日期的前n天，后m天
tool.getTempDate = function (time, n, m) {
  var temp = Date.parse(new Date(time.replace(/-/, "/")));
  var beginTime = temp - n;
  var endTime = beginTime + m;
  beginTime = tool.handelTimeStrToDate(beginTime);
  endTime = tool.handelTimeStrToDate(endTime);
  return {
    beginTime,
    endTime,
  };
};

/**
 * 数据分组
 * @param {Object[]} array 处理的对象数组
 * @param {Object} f 回调函数 e.g. item => item.name
 * @returns {Object} key值是分组字段，value 是数组[]，例如 {'banana': [], 'apple': []}
 */
tool.groupBy = function (array, f) {
  const groups = {};
  array.forEach((o) => {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });

  return groups;
};
/**
 * 判断浏览器类型
 * @returns
 */
tool.isIE = function () {
  if (!!window.ActiveXObject || "ActiveXObject" in window) {
    return true;
  } else {
    return false;
  }
};
//获取url上数据
tool.getQueryString = function (name, win) {
  let w = win || window;
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let search = w.location.search.substr(1);
  let href = w.location.href;
  if (href.indexOf("?") >= 0) {
    search = href.substr(href.indexOf("?") + 1);
  }
  let r = search.match(reg);

  if (r) {
    return decodeURIComponent(r[2]);
  }
  return null;
};
//获取项目名
tool.getProName = function () {
  let proName = window.location.pathname.split("/")[1];
  return "/" + proName;
};

tool.getUserUid = function () {
  return tool.getQueryString("_UID");
};

tool.dateFormat = function (fmt, date) {
  let ret;
  const opt = {
    "y+": date.getFullYear().toString(), // 年
    "M+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "m+": date.getMinutes().toString(), // 分
    "s+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
};
/***
 * 将标准格式的时间字符串转为日期格式，毫秒数就不用了。
 */
tool.toDate = function (value) {
  //因为是标准的时间格式，所以可以这么做
  var dd = {
    year: value.substring(0, 4) || 2000,
    month: value.substring(5, 7) || 1,
    day: value.substring(8, 10) || 1,
    hour: value.substring(11, 13) || 0,
    min: value.substring(14, 16) || 0,
    sec: value.substring(17, 19) || 0,
  };
  var month = Number(dd.month) - 1;
  return new Date(dd.year, month, dd.day, dd.hour, dd.min, dd.sec);
};

tool.handleDateRangeChange = function (val) {
  const date = new Date();
  let endTime = tool.dateFormat("yyyy-MM-dd", new Date()) + " 23:59:59",
    startTime = "";
  switch (val) {
    case "0day":
      startTime = tool.dateFormat("yyyy-MM-dd", new Date()) + " 00:00:00";
      break;
    case "1day":
      startTime =
        tool.dateFormat(
          "yyyy-MM-dd",
          new Date(date.getTime() - 24 * 3600 * 1000 * 1)
        ) + " 00:00:00";
      break;
    case "3day":
      startTime =
        tool.dateFormat(
          "yyyy-MM-dd",
          new Date(date.getTime() - 24 * 3600 * 1000 * 3)
        ) + " 00:00:00";
      break;
    case "7day":
      startTime =
        tool.dateFormat(
          "yyyy-MM-dd",
          new Date(date.getTime() - 24 * 3600 * 1000 * 7)
        ) + " 00:00:00";
      break;
    case "30day":
      startTime =
        tool.dateFormat(
          "yyyy-MM-dd",
          new Date(date.getTime() - 24 * 3600 * 1000 * 30)
        ) + " 00:00:00";
      break;
  }
  let times = {
    startTime: startTime,
    endTime: endTime,
  };
  return times;
};
//将时间格式转为字符串 如：2021-06-28 00:00:00  -》20210628000000
tool.changeToDefaultFormat = function (data) {
  let str =
    (data && typeof data == "string" && data.replace(/[:\s-]+/g, "")) || data;
  return str;
};
// 时间戳转日期格式
tool.handelTimeStrToDate = function (data) {
  function add0(m) {
    return m < 10 ? "0" + m : m;
  }
  var time = new Date(data);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return (
    y +
    "-" +
    add0(m) +
    "-" +
    add0(d) +
    " " +
    add0(h) +
    ":" +
    add0(mm) +
    ":" +
    add0(s)
  );
};

// Canvas转base64图片
tool.convertCanvasToImage = function (canvas) {
  let image = new Image();
  image.src = canvas.toDataURL("image/png");
  return image.src;
};

tool.getWindowHeight = () => {
  let clientHeight = 0;
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight =
      document.body.clientHeight < document.documentElement.clientHeight
        ? document.body.clientHeight
        : document.documentElement.clientHeight;
  } else {
    clientHeight =
      document.body.clientHeight > document.documentElement.clientHeight
        ? document.body.clientHeight
        : document.documentElement.clientHeight;
  }
  return clientHeight;
};

tool.showErrorMsg = (that, msg) => {
  const offTop = tool.getWindowHeight() / 2 - 20;
  that.$message({
    showClose: true,
    message: msg,
    type: "error",
    offset: offTop,
    center: true,
  });
};

/*
 * 格式化千分位参数说明：
 * number：要格式化千分位的数字
 * */
tool.numberFormat = (num) => {
  return (num + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
};
//提示信息
tool.showMsg = (that, msg, type, customClass, duration) => {
  const offTop = tool.getWindowHeight() / 2 - 20;
  that.$message({
    showClose: true,
    message: msg,
    type: type,
    offset: offTop,
    center: true,
    customClass: customClass || "",
    duration: duration || 3000,
  });
};
tool.getImgUrl = (item, type) => {
  if (!item.SubImageList) {
    return "";
  }
  let faceImgList = item.SubImageList.filter((item) => item.Type === type);
  return faceImgList && faceImgList.length > 0
    ? faceImgList[0].StoragePath
    : "";
};
//获取重点人大图
tool.getZdrSmallPic = (item) => {
  let src = item.SmallImageData
    ? tool.combineBase64url(item.SmallImageData)
    : item.SmallImageStoragePath;
  return src;
};
tool.getZdrBigPic = (item) => {
  let src = item.BigImageData
    ? tool.combineBase64url(item.BigImageData)
    : item.BigImageStoragePath;
  return src;
};

//获取树id数组
tool.formatTreeData = (data) => {
  let nodeArry = [];
  nodeArry = tool.getTreeId(data, nodeArry, nodeArry);
  // console.log(nodeArry);
  return nodeArry;
};
tool.getTreeId = (data, nodeArry) => {
  data.forEach((node) => {
    nodeArry.push(node.Id);
    if (node.Children && node.Children.length > 0) {
      tool.getTreeId(node.Children, nodeArry);
    }
  });
  return nodeArry;
};
// *@params startDate 开始时间
// *@params endDate 结束时间
// *@params timeUnit 时间间隔,注意是毫秒数
// **/常用的时间刻度60000:分钟桶600000:十分钟桶  3600000: 小时桶  86400000: 天桶  时间桶的概念，就是以该间隔作为时间刻度
tool.createTimeUnitListByTimeRange = (startDate, endDate, timeUnit) => {
  let startSeconds = new Date(startDate).getTime();
  let endSeconds = new Date(endDate).getTime();

  // 必须用计算机初始时间的时间戳来作为基准点，否则时区会影响初始时间戳毫秒数
  let base = new Date("1970-01-01 00:00:00").getTime();

  let rangeTimeUnitList = [];
  let firstDegree;

  // 第一个刻度，可能刚好在你需要的整点刻度上，如果不在整点上，减去多余的部分，往前推一个刻度。
  // 此处就是减掉基准时间戳再执行整除操作，否则如果以天为刻度，整除86400000，得到的第一个刻度会是包含时区的时间，如北京时间：2020-09-10 08：00：00
  firstDegree = startSeconds - ((startSeconds - base) % timeUnit);

  rangeTimeUnitList.push(
    tool.dateFormat("yyyy-MM-dd HH:mm:ss", new Date(firstDegree))
  );

  // 当最后一个刻度大于截止时间，停止创建刻度数据
  while (firstDegree < endSeconds) {
    firstDegree += timeUnit;
    rangeTimeUnitList.push(
      tool.dateFormat("yyyy-MM-dd HH:mm:ss", new Date(firstDegree))
    );
  }

  return rangeTimeUnitList;
};

tool.extend = function () {
  let options,
    name,
    src,
    copy,
    copyIsArray,
    clone,
    target = arguments[0] || {},
    i = 1,
    length = arguments.length,
    deep = false;

  // Handle a deep copy situation
  if (typeof target === "boolean") {
    deep = target;

    // Skip the boolean and the target
    target = arguments[i] || {};
    i++;
  }

  // Handle case when target is a string or something (possible in deep copy)
  if (typeof target !== "object" && !this.isFunction(target)) {
    target = {};
  }

  // Extend jQuery itself if only one argument is passed
  if (i === length) {
    target = this;
    i--;
  }

  for (; i < length; i++) {
    // Only deal with non-null/undefined values
    if ((options = arguments[i]) != null) {
      // Extend the base object
      for (name in options) {
        copy = options[name];

        // Prevent Object.prototype pollution
        // Prevent never-ending loop
        if (name === "__proto__" || target === copy) {
          continue;
        }

        // Recurse if we're merging plain objects or arrays
        if (
          deep &&
          copy &&
          (this.isPlainObject(copy) || (copyIsArray = Array.isArray(copy)))
        ) {
          src = target[name];

          // Ensure proper type for the source value
          if (copyIsArray && !Array.isArray(src)) {
            clone = [];
          } else if (!copyIsArray && !this.isPlainObject(src)) {
            clone = {};
          } else {
            clone = src;
          }
          copyIsArray = false;

          // Never move original objects, clone them
          target[name] = this.extend(deep, clone, copy);

          // Don't bring in undefined values
        } else if (copy !== undefined) {
          target[name] = copy;
        }
      }
    }
  }

  // Return the modified object
  return target;
};

tool.isFunction = function (obj) {
  // Support: Chrome <=57, Firefox <=52
  // In some browsers, typeof returns "function" for HTML <object> elements
  // (i.e., `typeof document.createElement( "object" ) === "function"`).
  // We don't want to classify *any* DOM node as a function.
  // Support: QtWeb <=3.8.5, WebKit <=534.34, wkhtmltopdf tool <=0.12.5
  // Plus for old WebKit, typeof returns "function" for HTML collections
  // (e.g., `typeof document.getElementsByTagName("div") === "function"`). (gh-4756)
  return (
    typeof obj === "function" &&
    typeof obj.nodeType !== "number" &&
    typeof obj.item !== "function"
  );
};

const class2type = {};
const toString = class2type.toString;
const hasOwn = class2type.hasOwnProperty;
const fnToString = hasOwn.toString;
const getProto = Object.getPrototypeOf;
const ObjectFunctionString = fnToString.call(Object);

tool.isPlainObject = function (obj) {
  let proto, Ctor;

  // Detect obvious negatives
  // Use toString instead of jQuery.type to catch host objects
  if (!obj || toString.call(obj) !== "[object Object]") {
    return false;
  }

  proto = getProto(obj);

  // Objects with no prototype (e.g., `Object.create( null )`) are plain
  if (!proto) {
    return true;
  }

  // Objects with prototype are plain iff they were constructed by a global Object function
  Ctor = hasOwn.call(proto, "constructor") && proto.constructor;
  return (
    typeof Ctor === "function" && fnToString.call(Ctor) === ObjectFunctionString
  );
};
//视频监控--单个摄像机
tool.video = (that, Id, Name) => {
  let mapConfig = that.$mapConfig;
  //
  if (!mapConfig.videoPlayer) {
    let systemConfig = localStorage.getItem("systemConfig");
    if (systemConfig) {
      mapConfig.videoPlayer = JSON.parse(systemConfig).videoPlayer;
    }
    if (!mapConfig.videoPlayer) {
      tool.showMsg(that, "未配置播放插件", "error");
      return;
    }
  }

  if (mapConfig.videoPlayer == "webapp") {
    let userInfo = that.$mapConfig.userInfo;
    let _mon = tool.getComplexId(Id.split("-")[0], Id.split("-")[1]);
    let url =
      "webapp://add=" +
      userInfo.UasIp +
      "&usr=" +
      userInfo.EmailAddr +
      "&port=" +
      userInfo.UasPort +
      "&pwd=" +
      userInfo.UserPw +
      "&md5=" +
      userInfo.UserPw +
      "&rst=1&mon=" +
      _mon +
      "&clt=RGB(32,32,32)&top=1";
    tool.openWebapp(url, that);
  } else if (mapConfig.videoPlayer == "vauc") {
    //视频赋能增加判断该设备是否有权限
    let page = that.$pageConfig.page;
    let pageName = page || tool.getQueryString("page");
    let url, list;
    if (pageName == "spfnVideoMonitor") {
      let hasAuth = "1";
      that.$api.spfnGetDeviceOpt(Id).then((res) => {
        let data = res.data;
        if (data.ResponseStatus.StatusCode == 0) {
          let ReadRight = data.Results.ReadRight;
          if (ReadRight != 1) {
            //没有权限
            hasAuth = "0";
          }
          localStorage.setItem("spfnReadRight", hasAuth);
          // 获取视频url并存入store
          url = window.vauc.getPlayUrl(Id, 1);
          //vauc未登录提示
          if (!url) {
            tool.showVaucUnlogin(that);
            return;
          }
          list = [{ url: url, id: Id, title: Name }];
          //显示播放组件窗口
          tool.showVideoPlaying(that, list);
        }
      });
    } else {
      window.vauc.searchCamera(
        {
          Id: Id,
        },
        (res) => {
          if (res.Results == "0") {
            that.$message.warning("设备不在线");
            return;
          } else if (!res.Results) {
            that.$message.warning("该设备没有操作权限");
            return;
          }
          // 获取视频url并存入store
          url = window.vauc.getPlayUrl(Id);
          list = [{ url: url, id: Id, title: Name }];
          tool.showVideoPlaying(that, list);
        }
      );
    }
  }
};
tool.showVideoPlaying = (that, list) => {
  // 单个点位播放
  that.$store.dispatch({
    type: that.getModuleName("setVideoSplitScreen"),
    screen: 1,
  });
  that.$store.dispatch({
    type: that.getModuleName("setShowVideoDialog"),
    data: true,
  });
  that.$store.dispatch({
    type: that.getModuleName("setPageDetail"),
    show: true,
    data: list,
    name: "VideoPlaying",
    unique: true,
  });
};
//录像回放
tool.videoReplay = (that, Id, Name, time) => {
  let mapConfig = that.$mapConfig;
  if (!mapConfig.videoPlayer) {
    let systemConfig = localStorage.getItem("systemConfig");
    if (systemConfig) {
      mapConfig.videoPlayer = JSON.parse(systemConfig).videoPlayer;
    }
    if (!mapConfig.videoPlayer) {
      tool.showMsg(that, "未配置播放插件", "error");
      return;
    }
  }
  if (mapConfig.videoPlayer == "webapp") {
    let userInfo = that.$mapConfig.userInfo;
    let now = new Date().getTime();
    let startDate = new Date(now - 10 * 60 * 1000);
    let endDate = new Date();
    let beginTime = tool.dateFormat("yyyy-MM-dd HH:mm:ss", startDate);
    let endTime = tool.dateFormat("yyyy-MM-dd HH:mm:ss", endDate);
    if (beginTime) {
      beginTime = beginTime
        .replace(/-/g, "")
        .replace(/\s+/g, "")
        .replace(/:/g, "");
    }
    if (endTime) {
      endTime = endTime.replace(/-/g, "").replace(/\s+/g, "").replace(/:/g, "");
    }
    if (time) {
      //如果回放传了开始时间，结束时间为开始时间上加5s
      beginTime = tool.dateFormat("yyyyMMddHHmmss", time);
      let date2 = new Date(Date.parse(time) + 5000);
      endTime = tool.dateFormat("yyyyMMddHHmmss", date2);
    }
    let FDID = tool.getComplexId(Id.split("-")[0], Id.split("-")[1]);

    let url =
      "webapp://add=" +
      userInfo.UasIp +
      "&usr=" +
      userInfo.EmailAddr +
      "&port=" +
      userInfo.UasPort +
      "&pwd=" +
      userInfo.UserPw +
      "&md5=" +
      userInfo.UserPw +
      "&rst=1&rec=" +
      FDID +
      "&tree=0&wnum=1&cut=0&beg=" +
      beginTime +
      "&end=" +
      endTime;
    tool.openWebapp(url, that);
  } else if (mapConfig.videoPlayer == "vauc") {
    window.vauc.searchCamera(
      {
        Id: Id,
      },
      (res) => {
        if (res.Results == "0") {
          that.$message.warning("设备不在线");
          return;
        } else if (!res.Results) {
          that.$message.warning("该设备没有操作权限");
          return;
        }
        let message = {
          id: Id,
          title: Name,
        };
        if (time) {
          that.$store.dispatch({
            type: this.getModuleName("setCurrentPlaybackTime"),
            data: time,
          });
        }
        that.$store.dispatch({
          type: that.getModuleName("setVideoPlayerMessage"),
          message,
        });
        that.$store.dispatch({
          type: that.getModuleName("setVideoPlayBackMessage"),
          message,
        });
        that.$store.dispatch({
          type: that.getModuleName("setShowVideoDialog"),
          data: true,
        });
        that.$store.dispatch({
          type: that.getModuleName("setPageDetail"),
          show: true,
          data: [],
          name: "VideoPlayback",
          unique: true,
        });
      }
    );
  }
};
// 获取当前时间后多少s
/**
 *
 * @param {*} time  2022-10-24 10:20:20
 * * @param {*} n  后多少s
 */
tool.getTimeAfter = (time, n) => {
  let etime = new Date(Date.parse(time) + 1000 * n);
  let timeStr = tool.dateFormat("yyyyMMddHHmmss", etime);
  return timeStr;
};
// 获取当前时间前多少s
/**
 *
 * @param {*} time  2022-10-24 10:20:20
 * * @param {*} n  前多少s
 */
tool.getTimeBefore = (time, n) => {
  let stime = new Date(Date.parse(time) - 1000 * n);
  let timeStr = tool.dateFormat("yyyyMMddHHmmss", stime);
  return timeStr;
};
tool.getComplexId = (fdId, channelId) => {
  var strChannelId = tool.getChannelId(channelId);
  var compFdId =
    fdId.substring(0, 6) + "-" + fdId + "-0001" + "-" + strChannelId;
  return compFdId;
};
tool.getChannelId = (channelId) => {
  var tChannelId = parseInt(channelId);
  if (tChannelId < 10) {
    channelId = "000" + tChannelId;
  } else if (tChannelId < 100) {
    channelId = "00" + tChannelId;
  } else if (tChannelId < 1000) {
    channelId = "0" + tChannelId;
  }
  return channelId;
};
//视频监控--多个摄像机
tool.playVideos = (that, data) => {
  if (!data || data.length == 0) {
    return;
  }
  let mapConfig = that.$mapConfig;
  if (!mapConfig.videoPlayer) {
    let systemConfig = localStorage.getItem("systemConfig");
    if (systemConfig) {
      mapConfig.videoPlayer = JSON.parse(systemConfig).videoPlayer;
    }
    if (!mapConfig.videoPlayer) {
      tool.showMsg(that, "未配置播放插件", "error");
      return;
    }
  }
  if (mapConfig.videoPlayer == "webapp") {
    var fdArray = [];
    for (var i = 0; i < data.length; i++) {
      if (i > 15) {
        break;
      }
      let Id = data[i].Id;
      let fdid = Id.split("-")[0];
      let channelid = Id.split("-")[1];
      let id = tool.getComplexId(fdid, channelid);
      fdArray.push(id + ":" + i);
    }
    let userInfo = that.$mapConfig.userInfo;
    let href =
      "webapp://add=" +
      userInfo.UasIp +
      "&usr=" +
      userInfo.EmailAddr +
      "&port=" +
      userInfo.UasPort +
      "&pwd=" +
      userInfo.UserPw +
      "&md5=" +
      userInfo.UserPw;
    href =
      href +
      "&rst=1&mon=" +
      fdArray.join(";") +
      "&wnum=" +
      fdArray.length +
      ";";
    tool.openWebapp(href, that);
  } else if (mapConfig.videoPlayer == "vauc") {
    //vauc未登录提示
    let playurl = window.vauc.getPlayUrl(data[0].Id);
    if (!playurl) {
      tool.showVaucUnlogin(that);
      return;
    }
    let list = data.map((val) => {
      let obj = {
        id: val.Id,
        title: val.Label || val.Name,
        url: window.vauc.getPlayUrl(val.Id),
      };
      return obj;
    });

    let screen = 1;
    if (list.length == 1) screen = 1;
    else if (list.length > 1) screen = 4;
    // else if(list.length>4&&list.length<10) screen = 9
    // else if(list.length>9) screen = 16
    // 最大4分屏，超出取前4条
    if (list.length > 4) {
      list = list.slice(0, 4);
    }
    // 多个点位播放
    that.$store.dispatch({
      type: that.getModuleName("setVideoSplitScreen"),
      screen: screen,
    });
    that.$store.dispatch({
      type: that.getModuleName("setShowVideoDialog"),
      data: true,
    });
    that.$store.dispatch({
      type: that.getModuleName("setPageDetail"),
      show: true,
      data: list,
      name: "VideoPlaying",
      unique: true,
    });
  }
};
// 多路播放带列表带分页按钮
tool.playVideosWithPage = (that, data) => {
  if (!data || data.length == 0) {
    return;
  }
  let mapConfig = that.$mapConfig;
  if (!mapConfig.videoPlayer) {
    let systemConfig = localStorage.getItem("systemConfig");
    if (systemConfig) {
      mapConfig.videoPlayer = JSON.parse(systemConfig).videoPlayer;
    }
    if (!mapConfig.videoPlayer) {
      tool.showMsg(that, "未配置播放插件", "error");
      return;
    }
  }
  if (mapConfig.videoPlayer == "webapp") {
    var fdArray = [];
    for (var i = 0; i < data.length; i++) {
      if (i > 15) {
        break;
      }
      let Id = data[i].Id;
      let fdid = Id.split("-")[0];
      let channelid = Id.split("-")[1];
      let id = tool.getComplexId(fdid, channelid);
      fdArray.push(id + ":" + i);
    }
    let userInfo = that.$mapConfig.userInfo;
    let href =
      "webapp://add=" +
      userInfo.UasIp +
      "&usr=" +
      userInfo.EmailAddr +
      "&port=" +
      userInfo.UasPort +
      "&pwd=" +
      userInfo.UserPw +
      "&md5=" +
      userInfo.UserPw;
    href =
      href +
      "&rst=1&mon=" +
      fdArray.join(";") +
      "&wnum=" +
      fdArray.length +
      ";";
    tool.openWebapp(href, that);
  } else if (mapConfig.videoPlayer == "vauc") {
    //vauc未登录提示
    let playurl = window.vauc.getPlayUrl(data[0].Id);
    if (!playurl) {
      tool.showVaucUnlogin(that);
      return;
    }
    let list = data.map((val) => {
      let obj = {
        id: val.Id,
        title: val.Name,
        DeviceId: val.Id,
        // url:window.vauc.getPlayUrl(val.Id),
      };
      return obj;
    });
    that.$store.dispatch({
      type: that.getModuleName("setPageDetail"),
      show: true,
      data: list,
      detailName: "VideosPlaying",
      name: "VideosPlaying",
    });
  }
};
tool.objToQueryStr = function (obj) {
  var str = "";
  for (var key in obj) {
    if (obj[key] || obj[key] === false || obj[key] === 0) {
      str += key + "=" + encodeURIComponent(obj[key]) + "&";
    }
  }
  return str;
};
tool.removeClass = function (ele, txt) {
  var str = ele.className;
  if (typeof txt == "object") {
    txt.forEach((item) => {
      let index = str.indexOf(item);
      if (index > -1) {
        ele.className = str.replace(item, "");
      }
    });
  }
};
// yyyy-mm-dd hh:mm:ss --》毫秒数
tool.dateTimeToMin = function (date) {
  var startDate = date.replace(/-/g, "/");
  var startDateM = new Date(startDate).getTime(); //得到毫秒数
  return startDateM;
};
//毫秒数 --》yyyy-mm-dd hh:mm:ss
tool.minToDateTime = function (minute) {
  var startDate = new Date(minute)
    .toJSON()
    .replace(/T|Z|(\.\d{3})/g, " ")
    .trim();
  return startDate;
};
// 一维数组--》二维
tool.groupList = function (array, group) {
  let index = 0;
  let newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, (index += group)));
  }
  return newArray;
};
// 文件下载
tool.downloadUrl = function (url) {
  const a = document.createElement("a");
  a.href = url;
  a.download = "video";
  a.click();
};

//打开菜单
tool.open = (menu) => {
  let opStatus = tool.getQueryString("OpStatus");
  if (opStatus == "true") {
    window.open(menu.ExtUrl);
    return;
  }
  if (!menu.MenuId) {
    menu.MenuId = +new Date();
  }
  tool.addTimestamp(menu);
  top.postMessage({ type: "showTab", data: menu }, "*");
};

tool.addTimestamp = (menu) => {
  if (menu.Url) {
    if (!menu.OrginalUrl) {
      menu.OrginalUrl = menu.Url;
    }
    menu.Url = tool.addTimestampParam(menu.OrginalUrl);
  }

  if (menu.ExtUrl) {
    if (!menu.OrginalExtUrl) {
      menu.OrginalExtUrl = menu.ExtUrl;
    }
    menu.ExtUrl = tool.addTimestampParam(menu.OrginalExtUrl);
  }
};

tool.addTimestampParam = (url) => {
  if (url && url.indexOf("_timestamp") < 0) {
    if (url.indexOf("?") > 0) {
      url += "&_timestamp=" + new Date().getTime();
    } else {
      url += "?_timestamp=" + new Date().getTime();
    }
  }
  return url;
};
// vauc返回code提示
tool.showMsgVauc = function (that, code) {
  if (code == "1") {
    tool.showMsg(that, "token失效，重新登录中，请稍后...", "warning");
    window.vauc.reLogin();
  } else if (code == "2") {
    tool.showMsg(that, "设备不存在", "warning");
  } else if (code == "3") {
    tool.showMsg(that, "url格式错误", "warning");
  } else if (code == "4") {
    tool.showMsg(that, "无开始时间", "warning");
  } else if (code == "5") {
    tool.showMsg(that, "找不到记录文件", "warning");
  } else if (code == "6") {
    tool.showMsg(that, "设备不在线或设备不存在", "warning");
  } else if (code == "7") {
    tool.showMsg(that, "用户登录失败", "warning");
  } else if (code == "8") {
    tool.showMsg(that, "获取文件列表失败", "warning");
  }
  // else if(code == '9'){
  //   tool.showMsg(that,'分页无效','warning')
  // }else if(code == '10'){
  //   tool.showMsg(that,'playid无效','warning')
  // }else if(code == '11'){
  //   tool.showMsg(that,'ctrltype无效','warning')
  // }else if(code == '12'){
  //   tool.showMsg(that,'type无效','warning')
  // }else if(code == '13'){
  //   tool.showMsg(that,'pos无效','warning')
  // }else if(code == '14'){
  //   tool.showMsg(that,'speed无效','warning')
  // }else if(code == '15'){
  //   tool.showMsg(that,'type无效','warning')
  // }
  // else if(code == '16'){
  // tool.showMsg(that,'camid无效','warning')
  // }
  // else if(code == '17'){
  //   tool.showMsg(that,'action无效','warning')
  // }else if(code == '18'){
  //   tool.showMsg(that,'presetID无效','warning')
  // }else if(code == '19'){
  //   tool.showMsg(that,'presetName无效','warning')
  // }else if(code == '20'){
  //   tool.showMsg(that,'name无效','warning')
  // }else if(code == '21'){
  //   tool.showMsg(that,'param无效','warning')
  // }else if(code == '22'){
  //   tool.showMsg(that,'time无效','warning')
  // }
  // else if(code == '23'){
  //   tool.showMsg(that,'正在下载记录','warning')
  // }
  // else if(code == '24'){
  //   tool.showMsg(that,'path无效','warning')
  // }
  else if (code == "25") {
    tool.showMsg(that, "登录参数无效", "warning");
  }
  // else if(code == '26'){
  //   tool.showMsg(that,'重复登录','warning')
  // }else if(code == '27'){
  //   tool.showMsg(that,'name失效','warning')
  // }else if(code == '28'){
  //   tool.showMsg(that,'value失效','warning')
  // }
  // else if(code == '29'){
  //   tool.showMsg(that,'areaid不存在','warning')
  // }
  // else if(code == '30'){
  //   tool.showMsg(that,'未查询到记录','warning')
  // }
  // else if(code == '31'){
  //   tool.showMsg(that,'超出许可证限制','warning')
  // }else if(code == '32'){
  //   tool.showMsg(that,'超过视频计数限制','warning')
  // }else if(code == '33'){
  //   tool.showMsg(that,'正在登录中','warning')
  // }
  else if (code == "50") {
    tool.showMsg(that, "登录平台失败", "warning");
  } else if (code == "101") {
    tool.showMsg(that, "响应超时", "warning");
  } else if (code == "102") {
    tool.showMsg(that, "响应异常", "warning");
  } else if (code == "103") {
    tool.showMsg(that, "响应错误", "warning");
  } else if (code == "104") {
    tool.showMsg(that, "登录平台异常", "warning");
  } else if (code == "105") {
    tool.showMsg(that, "登录异常", "warning");
  } else if (code == "106") {
    tool.showMsg(that, "下载地址不存在", "warning");
  } else if (code == "201") {
    tool.showMsg(that, "mk响应错误", "warning");
  }
};
//将时间格式转为字符串 如：2021-06-28 00:00:00  -》20210628000000
tool.handelDateChangeStr = function (data) {
  let str =
    (data && typeof data == "string" && data.replace(/[:\s-]+/g, "")) || data;
  return str;
};
//打开弹窗
/*options参数
  {height:900,width:1200,url:"http://localhost:9088/ui/search?dtableName=test_list&requestType=dsearch&notitle=1&search_template=12",
  context:this,
  buttons:[{text:'确定',handler(dialog){console.log('确定啦',dialog);console.log(dialog.getContentWindow());dialog.close();}},
    {text:'取消',handler(dialog){dialog.close()}}]}
*/
tool.openDialog = (options) => {
  window.__vm._dialog.open(options);
};

//关闭弹窗
tool.closeDialog = (id) => {
  window.__vm._dialog.close(id);
};

//获取弹窗内的window
tool.getContentWindow = (id) => {
  return window.__vm._dialog.getContentWindow(id);
};
tool.showVaucUnlogin = (that) => {
  tool.postMessage(parent, "showVideoVauc", false);
  let id = "_vauclogin";
  tool.getPluginLink(that, id);
};
tool.getPluginLink = (that, id) => {
  let win = that || window.__vm;
  api.getPluginLink("2").then((res) => {
    let link = res.data.Results.Url;
    win._dialog.alert(
      `vauc未登录！如未安装视频插件请点击<a href="${link}"  style="color:#0f74dc;cursor: pointer;">下载</a>安装,已安装请刷新页面`,
      () => {},
      {
        width: 550,
        id: id,
        buttons: [
          {
            text: "关闭",
            handler() {
              win._dialog.hide(id);
            },
          },
        ],
      }
    );
  });
};
tool.getUUID = () => {
  var s = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  var uuid = s() + s() + s() + s();
  return uuid;
};
// 格式单位
tool.formatUnit = (value) => {
  let unit = "";
  if (value >= 100000000) {
    value = (value / 100000000).toFixed(2);
    unit = "亿";
  } else if (value >= 10000) {
    value = (value / 10000).toFixed(2);
    unit = "万";
  } else {
    unit = "";
  }
  return value + unit;
};
// 预置点到达
tool.handelPreset = (node) => {
  window.vauc.preset(
    {
      Action: "goto",
      Id: node.Attributes.Parent,
      PresetID: node.Id,
      PresetName: node.Label,
    },
    (res) => {
      console.log(res);
    },
    (err) => {
      console.log(err);
    }
  );
};
//打开webapp
tool.openWebapp = (url) => {
  // console.log(that);
  window.location.href = url;
};
tool.showVaucUnCancel = (that) => {
  let id = "_vaucCancel";
  let win = that || window.__vm;
  api.getPluginLink("2").then((res) => {
    let link = res.data.Results.Url;
    win._dialog.alert(
      `未安装视频插件请点击<a href="${link}" style="color:#0f74dc;cursor: pointer;">下载</a>安装,已安装请刷新页面`,
      () => {},
      {
        width: 550,
        id: id,
        buttons: [
          {
            text: "关闭",
            handler() {
              win._dialog.hide(id);
            },
          },
        ],
      }
    );
  });
};
// 将参数拼接在接口地址后面
tool.getParamsData = (data) => {
  let params = "?";
  for (let key in data) {
    params += key + "=" + data[key] + "&";
  }
  params = params.slice(0, params.length - 1);
  return params;
};

/* 号牌号码背景 */
tool.getPlateBack = function (val) {
  if (val == "小型汽车") {
    return "bg-lanp info-vehicle";
  } else if (val == "大型汽车") {
    return "bg-huangp info-vehicle";
  } else if (val == "小型新能源汽车" || val == "大型新能源汽车") {
    return "bg-lvp info-vehicle";
  } else {
    return "info-vehicle";
  }
};
/* 车辆类型背景 */
tool.getPlateTypeBack = function (val) {
  if (val == "大型汽车") {
    return "bg-huang-type info-vehicle-type";
  } else if (
    val == "小型新能源汽车" ||
    val == "大型新能源汽车" ||
    val == "小型汽车"
  ) {
    return "bg-lan-type info-vehicle-type";
  } else {
    return "info-vehicle-type";
  }
};
//返回登录
tool.toLogin = () => {
  let loginUrl = "/app-website/lyte/login.html";
  top.location.href = loginUrl;
};

/**
 * 由一个组件，向上找到最近的指定组件
 * @param {*} context 当前上下文，比如你要基于哪个组件来向上寻找，一般都是基于当前的组件，也就是传入 this
 * @param {*} componentName 要找的组件的 name
 */
tool.findComponentUpward = (context, componentName) => {
  let parent = context.$parent;
  let name = parent.$options.name;

  while (parent && (!name || [componentName].indexOf(name) < 0)) {
    parent = parent.$parent;
    if (parent) name = parent.$options.name;
  }
  return parent;
};
// 经纬度最多保留几位小数
tool.roundFixed = (num, fixed) => {
  let nums = (num + "").split(".");
  if (nums.length >= 2) {
    let fixedNum = nums[1];
    if (fixedNum.length > fixed) {
      let fixedEndNum = fixedNum.substring(0, fixed);
      return nums[0] + "." + fixedEndNum;
    } else {
      return num;
    }
  } else {
    return num;
  }
};
tool.copyText = (that, value) => {
  // 复制
  const text = document.createElement("textarea"); // 创建节点
  text.setAttribute("readonly", "readonly");
  text.value = value; // 赋值
  document.body.appendChild(text); // 插入节点
  text.setSelectionRange(0, text.value.length);
  text.select(); // 选中节点
  document.execCommand("copy"); // 执行浏览器复制方法
  if (document.body.removeChild(text)) {
    tool.showMsg(that, "复制成功", "success");
  } else {
    tool.showMsg(that, "复制失败", "error");
  }
};
export default tool;
