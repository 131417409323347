import axios from "axios";
import { baseUrl } from "./const";
import tool from "../utils/tool";

axios.defaults.baseURL = baseUrl;

//设置token
const setToken = (config) => {
  let token = tool.getTempItem("token") || tool.getQueryString("Token");
  if (token) {
    config.headers.common["Authorization"] = token;
  }
  config.headers.common["Location-IP"] = window.location.host;
  config.headers.common["Location-Origin"] = window.location.origin;
  return config;
};

//设置请求头
axios.interceptors.request.use(
  (config) => {
    return setToken(config);
  },
  (err) => {
    return Promise.reject(err);
  }
);

//创建axios实例，该实例校验返回数据格式，返回数据格式必须是规范格式
const standardAxios = axios.create({
  baseURL: baseUrl,
});
//request
standardAxios.interceptors.request.use(
  (config) => {
    return setToken(config);
  },
  (err) => {
    return Promise.reject(err);
  }
);

//response
standardAxios.interceptors.response.use((res) => {
  if (!res.data.ResponseStatus || res.data.ResponseStatus.StatusCode !== 0) {
    return Promise.reject(res);
  }
  return res;
});
// 超时登录提示
axios.interceptors.response.use((res) => {
  let response = res.data.ResponseStatus;
  if (response && (response.StatusCode == 10 || response.StatusCode == 15)) {
    window.__vm._dialog.alert(
      "登录超时，请重新登录！",
      () => {
        tool.toLogin();
      },
      {
        showClose: false,
        id: "_loginTimeout",
      }
    );
    return;
  }
  return res;
});
const api = {};

api.getHeaders = () => {
  return {
    "Location-IP": window.location.host,
    Authorization: tool.getTempItem("token"),
    "Location-Origin": window.location.origin,
  };
};
api.setAxios = () => {
  axios.interceptors.response.use((res) => {
    if (res.data.ResponseStatus && res.data.ResponseStatus.StatusCode !== 0) {
      return Promise.reject(res);
    }
    return res;
  });
};
//获取表单传输参数
api.getParams = (params) => {
  var param = new URLSearchParams();
  for (let field in params) {
    param.append(field, params[field]);
  }
  return param;
};
api.postData = (url, data) => {
  return axios.post(url, data);
};
api.getData = (url, data) => {
  return axios.get(url, data);
};

//查询菜单列表
api.getMenuList = (data) => {
  return axios.post("/Website/V1/Menu/List", data);
};
//新建菜单
api.addMenu = (data) => {
  return axios.post("/Website/V1/Menu/Insert", data);
};
//修改菜单
api.updateMenu = (data) => {
  return axios.post("/Website/V1/Menu/Update", data);
};
// 删除菜单
api.deleteMenu = (data) => {
  return axios.post("/Website/V1/Menu/Delete/" + data);
};
// 查询菜单详情
api.getMenuDetail = (data) => {
  return axios.post("/Website/V1/Menu/Detail", data);
};
// 删除菜单图片
api.deleteMenuExt = (data) => {
  return axios.post("/Website/V1/Menu/Ext/Delete/" + data);
};
// 交换菜单顺序
api.sortMenu = (data) => {
  return axios.post("/Website/V1/Menu/Exchange", data);
};
//查询其他配置
api.getOtherConfig = () => {
  return axios.get("/Website/V1/Config/List");
};
// 修改其他配置
api.updateOtherConfig = (data) => {
  return axios.post("/Website/V1/Config/Update", data);
};

// 文章-新增/修改
api.addAticle = (data) => {
  return axios.post("/Website/V1/Article/Update", data);
};
// 文章-排序
api.sortAticleMore = (data) => {
  return axios.post("/Website/V1/Article/Exchange", data);
};
// 文章-删除
api.deleteAticle = (data) => {
  return axios.post("/Website/V1/Article/Delete/" + data);
};
// 文章-添加更多
api.addAticleMore = (data) => {
  return axios.post("/Website/V1/Article/Ext/Insert", data);
};
// 文章-更多修改
api.updateAticleMore = (data) => {
  return axios.post("/Website/V1/Article/Ext/Update", data);
};
// 文章-更多删除
api.deleteExtAticleMore = (data) => {
  return axios.post("/Website/V1/Article/Ext/Delete/" + data);
};
// 文章-更多排序
api.sortExtAticleMore = (data) => {
  return axios.post("/Website/V1/Article/Ext/Exchange", data);
};
// 文章-详情
api.getArticleDetail = (data) => {
  return axios.post("/Website/V1/Article/Detail", data);
};
// 文章-列表
api.getArticleList = (data) => {
  return axios.post("/Website/V1/Article/List", data);
};
// 首页内容
api.getHomeContent = () => {
  return axios.get("/Website/V1/Homepage/List");
};
api.uploadPic = (data) => {
  return axios.post("/Common/V2/File/Upload", data);
};
// 查询新闻上一篇和下一篇
api.getFrontAndBack = (data) => {
  return axios.post("/Website/V1/Article/FrontAndBack", data);
};
// 首页修改
api.updateHomePage = (data) => {
  return axios.post("/Website/V1/Homepage/Update", data);
};
api.getAuth = () => {
  return axios.post("/Website/V1/User/Auth");
};
export default api;
