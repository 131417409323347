//组件默认配置
import api from "../api/api";
export default {
  //默认的图片上传类型
  upload: {
    image: {
      width: 180,
      height: 200,
      accept: [".jpg", ".png", ".jpeg", ".gif", ".bmp"],
    },
    uploadName: "File",
    headers: api.getHeaders(),
    uploadUrl: "/Common/V2/File/Upload",
  },
  //查询页面
  datagrid: {
    //分页
    pageSize: 15,
    //分页数组
    pageSizes: [10, 15, 20, 50, 100, 200],
    //分页组件
    layout: "sizes,prev,pager,next,jumper,total,slot",
    //pager组件显示个数
    pagerCount: 5,
  },
  menuMap: {
    1001: "/home", //首页
    1002: "/product", //产品中心
    1003: "/solution", //解决方案
    1004: "/case", //核心案例
    "20240821112313046042": "/company", //关于我们
    0: "/error", //默认
    1006: "/about/company",
    100601: "/about/company",
    100602: "/about/news",
    100603: "/about/joinus",
    1005: "/server/serverguarantee",
    100501: "/server/serverguarantee",
    100502: "/server/network",
    100503: "/server/suggest",
  },
  menuIds: {
    home: "1001",
    product: "1002",
    solution: "1003",
    case: "1004",
    news: "100602",
    "/about/news": "1006",
    about: "1006",
    server: "1005",
    productdetail: "1002",
    company: "100601",
    newsdetail: "1006",
    joblist: "100603",
    zzry: "10060104", //资质荣誉
    solutiondetail: "1003",
    fwbz: "100501",
    fwwd: "100502",
    tsjy: "100503",
  },
  //服务支持锚链接
  hrefIds: {
    100501: "fwbz", //服务保障
    100502: "fwwd", //服务网点
    100503: "tsjy", //投诉建议
    100601: "gsjj", //公司简介
    10060101: "qywh", //企业文化
    10060102: "jsys", //技术优势
    10060103: "cptx", //产品体系
    10060104: "zzry", //资质荣誉
    10060105: "lxwm", //联系我们
  },
  pageName: {
    1002: "产品",
    1003: "方案",
    1004: "案例",
    100602: "文章",
    100603: "文章",
  },
};
