<template>
  <div>
    <p class="zjliyuan-error-page">~~~~~sorry page noot found</p>
  </div>
</template>
<script>
export default {
  name: "ErrorPage",
};
</script>

<style lang="scss">
.zjliyuan-error-page {
  color: #000;
  font-size: 16px;
  text-align: center;
}
</style>
