let regex = {
  SpecialCharacters: new RegExp(
    "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]"
  ), //特殊字符
  telPhone: /^[1][1-9][0-9]{9}$/,

  password:
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/,
};
const validUtil = {
  empty(value) {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === "number" && !Number.isNaN(value)) {
      return true;
    }
    if (typeof value === "object" && value !== null) {
      return Object.keys(value).length > 0;
    }
    if (typeof value === "string" && (!value.trim() || value === "")) {
      return false;
    }
    return !!value;
  },

  // 手机号校验
  telPhone(value) {
    return regex.telPhone.test(value);
  },
  timeRangeValidity(val) {
    //时间校验
    let startTime = new Date(val.StartTime).getTime();
    let endTime = new Date(val.EndTime).getTime();
    if (startTime >= endTime) {
      return "开始时间不能大于结束时间!";
    }
  },
  password(value) {
    return regex.password.test(value);
  },
};
export default validUtil;
