const baseUrl =
  process.env.NODE_ENV === "development"
    ? "/api"
    : process.env.VUE_APP_BASE_URL;
const baseHttpUrl = process.env.NODE_ENV === "development" ? "/api" : "";
const webSocketUrl =
  process.env.NODE_ENV === "development"
    ? "172.16.66.45"
    : window.location.host || "127.0.0.1";
const publicUrl =
  process.env.NODE_ENV === "development" ? process.env.BASE_URL : "";
module.exports = {
  baseUrl,
  baseHttpUrl,
  webSocketUrl,
  publicUrl,
};
