<template>
  <div class="card-type-1">
    <slot></slot>
    <div>
      <el-image class="card-type-1-image" :src="datas.PicUrl" @click="viewMore">
        <div slot="error" class="image-error-slot"></div>
        <!-- <div slot="placeholder" class="lyt-image-load-slot">加载中...</div> -->
      </el-image>
      <h3 class="zjly-ellipsis" @click="viewMore">{{ datas.Title }}</h3>
      <div class="card-type-content">{{ datas.Brief }}</div>
      <div class="card-type-bottom">
        <a @click="viewMore">了解更多<i></i></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardType1",
  props: {
    isLazy: {
      type: Boolean,
      default: true,
    },
    datas: {
      type: Object,
      default: () => {
        return {
          Title: "视频联网共享",
          PicUrl: "",
          Content:
            "系统遵从公安部“一机一档”系统建设思路，以及省、市的“一机一档”建设要求，立足公安实战需求，以设备档案全生命周期管理应用",
        };
      },
    },
  },
  data() {
    return {};
  },
  mounted() {
    // 假设你有一个包含所有el-image组件的数组
    // const images = this.$refs.images; // 这里的$refs需要根据你的实际情况来定
    // if (images && images.length) {
    //   // 强制最后一张图片加载
    //   const lastImage = images[images.length - 1];
    //   if (lastImage.$el) {
    //     // 假设el-image的图片是通过:src动态绑定的
    //     lastImage.$el.src = lastImage.$el.src;
    //   }
    // }
  },
  methods: {
    viewMore() {
      this.$emit("viewMore", this.datas);
    },
  },
};
</script>

<style lang="scss">
.card-type-1 {
  width: 372px;
  height: 488px;
  padding: 42px 36px 0;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 22px 0px rgba(24, 24, 24, 0.08);
  // box-shadow: 0 0 20px 0 rgba(24, 24, 24, 0.08);
  // border: #ccc 1px solid;
  border-radius: 10px;
  text-align: left;
  .card-type-1-image {
    width: 300px;
    height: 180px;
    margin-bottom: 48px;
    cursor: pointer;
    img {
      transition: all 0.3s;
      &:hover {
        transform: scale(1.05);
      }
    }
    .image-error-slot {
      width: 100%;
      height: 100%;
      background: #efefef;
    }
  }
  h3 {
    font-size: 22px;
    color: #181818;
    line-height: 29px;
    height: 29px;
    text-align: left;
    margin-bottom: 18px;
    cursor: pointer;
    &:hover {
      color: #b60005;
    }
  }
  .card-type-content {
    height: 63px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin-bottom: 57px;
    color: #6e6d72;
  }
}
</style>
